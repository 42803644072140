import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CoreHttpService } from '../../core/core-http.service';
import { RoleResponse, UserDetails, UserResponse } from '../users/usersModel';
import { API_ENDPOINTS } from '../projects/Task-management/constants';
@Injectable()
export class UsersService  {
  clientNumber: string;
  constructor(private coreHttpService: CoreHttpService) {
  }


  getUserslist() {
    this.clientNumber =  localStorage.getItem('clientNumber');
    return this.coreHttpService.httpGetRequest<UserResponse>(
      environment.apiBaseUrl + 'user/client/' + this.clientNumber + '/user'
    );
  }

  getOrganizationUsersList(organizationId: string) {
    
    return this.coreHttpService.httpGetRequest<UserResponse>(`${environment.apiBaseUrl}${API_ENDPOINTS.GET_ORGANIZATION_USERS_ENDPOINT}${organizationId}`);
  }
  

  // API for getting and binding User Role data to dropddown list
  getUserRole() {
    this.clientNumber =  localStorage.getItem('clientNumber');
    return this.coreHttpService.httpGetRequest<RoleResponse>(
      environment.apiBaseUrl + 'User/Client/' + this.clientNumber + '/Role'
    );
  }
  getUserRole1(args) {
    this.clientNumber =  localStorage.getItem('clientNumber');
    return this.coreHttpService.httpGetRequest<RoleResponse>(
      environment.apiBaseUrl + 'User/Client/' + this.clientNumber + '/Role/' + args
    );
  }

  // API for getting selected user details
  getUserDetails(userID) {
    this.clientNumber =  localStorage.getItem('clientNumber');
    return this.coreHttpService.httpGetRequest<UserDetails>(
     environment.apiBaseUrl + 'User/Client/' + this.clientNumber + '/User/ ' + userID
    );
  }

  // API for creating new user
  createUser(users, imageurl: string) {
    this.clientNumber =  localStorage.getItem('clientNumber');
    let headers = new HttpHeaders();
    // this is the important step. You need to set content type as null
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams()
      .set('FirstName', users.firstName)
      .set('LastName', users.lastName)
      .set('isActive', users.isActive)
      // .set('Email', users.email)
      .set('Roles', users.roles)
      .set('organizationId', users.organization)
      .set('jobTitle', users.jobTitle)
      // .set('Company_Name',users.companyName)
      .set('Address_line_1',users.address1)
      .set('Address_line_2',users.address2)
      .set('City',users.city)
      .set('country',users.country)
      .set('state',users.state)
      // .set('CompanyTelPhone',users.companyTelNo)
      .set('facebookURL',users.facebookURL)
      .set('twitterURL',users.twitterURL)
      .set('linkedinURL',users.linkedinURL)
      .set('Phone',users.contactPhoneNo)
      .set('email',users.contactEmail)
      .set('fax',users.contactFaxNo)
      .set('ProfilePicture', imageurl)
     // .set('Phone', users.phone)
      // .set('DOB', users.dob)
      .set('RoleIds', users.roles ? users.roles.toString() : '')
      .set('zipCode', users.zipCode);
    return this.coreHttpService.httpPostRequest<any,any>(
      environment.apiBaseUrl + 'user/client/' + this.clientNumber + '/user',
      params
    );
  }

  // API for updating new user
  updateUser(users, imageurl: string) {
    const params = new HttpParams()
      .set('userID', users.userID)
      .set('FirstName', users.firstName)
      .set('LastName', users.lastName)
      .set('organizationId', users.organization)
      .set('jobTitle', users.jobTitle)
      .set('isActive', users.isActive)
      .set('Email', users.email)
      .set('Roles', users.roles)
      .set('Company_Name',users.companyName)
      .set('Address_line_1',users.address1)
      .set('Address_line_2',users.address2)
      .set('City',users.city)
      .set('country',users.country)
      .set('state',users.state)
      .set('CompanyTelPhone',users.companyTelNo)
      .set('Phone',users.phone)
      .set('ProfilePicture', imageurl)
      .set('facebookURL',users.facebookURL)
      .set('linkedinURL',users.linkedinURL)
      .set('twitterURL',users.twitterURL)
      .set('fax',users.contactFaxNo)
      .set('isActive', users.isActive)
      .set('RoleIds', users.selectedroles.toString())
      .set('zipCode', users.zipCode);
    return this.coreHttpService.httpPutRequest(environment.apiBaseUrl + 'user/client/user', params);
  }

  getRoleId(){
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'user/GetUserRoles'
    );
  }

  checkUserIsAccoistedWithAnyProject(userId: string) {
    const url = `${environment.apiBaseUrl}user/checkUserIsAccoistedWithAnyProject?UserId=${userId}`;
    return this.coreHttpService.httpGetRequest(url);
  }
}
